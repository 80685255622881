import { findIndex } from 'lodash';
import { Component } from 'vue-property-decorator';
import Browser from '@/support/browser';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';

interface IPendingApprovalDto extends SUR.PendingApprovalDto {
    selected: boolean;
    busy: boolean;
    resultClass: string;
}

@Component
export default class PendingApprovalsComponent extends StudioPageBase {
    options = {
    };

    loading = true;
    loaded = false;
    busy = false;
    nopending = false;
    multipleMode = false;
    selectAll = false;
    selectedCount = 0;
    noSelection = true;

    refresh = {
        lastRefreshCheck: new Date(),
        playIntervalMs: 20 * 1000, // playing check interval time
    };

    timers = {
        refreshIntervalId: 0,
    };

    pendingApprovals: IPendingApprovalDto[] = [];
    pastApprovals: IPendingApprovalDto[] = [];

    created() {
        super.created('pendingApproval');

        Debug.setDebugModule('App', this);
    }

    mounted() {
        super.mounted();

        this.getPendingApprovalsList();
    }

    async getPendingApprovalsList() {
        try {
            let response = await PlayerApi.getPendingApprovals(this.studio.studio);

            this.nopending = response.length == 0;

            this.pendingApprovals.splice(0, this.pendingApprovals.length);

            response.forEach(approval => {
                let permObj = <IPendingApprovalDto>approval;
                permObj.selected = false;
                permObj.busy = false;

                switch (permObj.result) {
                    case 0: //SUR.PendingApprovalResult.None
                        permObj.resultClass = '';
                        break;
                    case 1: //SUR.PendingApprovalResult.Approved
                        permObj.resultClass = 'label-success';
                        break;
                    case 2: //SUR.PendingApprovalResult.Denied
                        permObj.resultClass = 'label-danger';
                        break;
                }


                if (permObj.isPending)
                    this.pendingApprovals.push(permObj);
                else
                    this.pastApprovals.push(permObj);
            });

            Debug.log('getPendingApprovalsList', this.pendingApprovals.length, 'PendingApprovals');
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Util.showError('Failed to get PendingApproval list ' + message);
            return null;
        } finally {
            this.loading = false;
            this.loaded = true;
            this.multipleMode = this.pendingApprovals.length > 1;
        }
    }

    approveSelected() {
        this.processSelected('approve', PlayerApi.approvePendingApproval);
    }

    denySelected() {
        this.processSelected('deny', PlayerApi.denyPendingApproval);
    }

    async processSelected(action: string, callback) {
        this.busy = true;
        let promises = [];

        this.pendingApprovals.forEach(approval => {
            if (approval.selected) {
                Debug.log('processSelected', action, approval.email, approval);
                approval.busy = true;
                promises.push(callback(approval));
            }
        });

        try {
            let result = await Promise.all(promises);

        } catch (err) {
            Util.showToast('Failed to ' + action + ': ' + err, true);
        } finally {
            this.pendingApprovals.forEach(approval => {
                approval.busy = false;
            });

            this.busy = false;
            await this.getPendingApprovalsList();
        }
    }

    async approve(approval: IPendingApprovalDto) {
        Debug.log('approve', approval.email, approval.studio);

        approval.busy = true;

        try {
            let response = await PlayerApi.approvePendingApproval(approval);

            let idx = findIndex(this.pendingApprovals, item => item.id == approval.id);
            if (idx != -1) {
                this.pendingApprovals.splice(idx, 1);
            }

            Util.showToast('Approved ' + approval.title + ' - ' + approval.email);

            Debug.log('approve', this.pendingApprovals.length, 'PendingApprovals', response);

            this.getPendingApprovalsList();

            if (this.pendingApprovals.length == 0) {
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            }
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Util.showError('Failed to approve ' + message);
            return null;
        } finally {
            approval.busy = false;
        }
    }

    async deny(approval: IPendingApprovalDto) {
        Debug.log('deny', approval.email, approval.studio);

        approval.busy = true;

        try {
            let response = await PlayerApi.denyPendingApproval(approval);

            let idx = findIndex(this.pendingApprovals, item => item.id == approval.id);
            if (idx != -1) {
                this.pendingApprovals.splice(idx, 1);
            }

            Util.showToast('Denied ' + approval.title + ' - ' + approval.email);
            Debug.log('Deny', this.pendingApprovals.length, 'PendingApprovals', response);

            this.getPendingApprovalsList();

            if (this.pendingApprovals.length == 0) {
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            }
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Util.showError('Failed to deny ' + message);
            return null;
        } finally {
            approval.busy = false;
        }
    }

    onSelect(approval) {
        Debug.log('onSelect', approval.email, approval);
        this.updateSelection();
    }

    onSelectAll() {
        this.pendingApprovals.forEach(approval => {
            approval.selected = this.selectAll;
        });
        this.updateSelection();
    }

    updateSelection() {
        let count = 0;
        this.pendingApprovals.forEach(approval => {
            if (approval.selected)
                count++;
        });

        this.selectedCount = count;
        this.noSelection = count == 0;
    }
}
